<template>
    <ul class="points" v-if="is_show">
        <li class="points__item"
            v-for="(point, index) in points"
            :key="point"
            :class="is_active(index)"
            @click="clickHandler(index)"
        ></li>
    </ul>
</template>

<script>
import {computed} from 'vue'

export default {
    name: "Points",
    props: {
        points: {
            type: Number,
            required: true,
            validator: (value) => {
                return value % 1 === 0
            }
        },
        index: {
            type: Number,
            default: 0
        },
        show: {
            type: Boolean,
            default: true
        },
        click: {
            type: Boolean,
            default: true
        },
    },
    setup(props, {emit}) {
        function clickHandler(index) {
            if (props.click) {
                emit('update:active', index)
                emit('onChange', index)
            }
        }

        const is_active = (index) => index === props.index ? 'points__item_active' : '';
        let is_show = computed(() => props.points && props.points > 1 && props.show)

        return {
            is_show,
            is_active,
            clickHandler
        }
    }
}
</script>

<style lang="less">
.points {
    @size: 10px;

    z-index: 99;
    position: absolute;
    bottom: 20px;
    transition: opacity .3s ease;
    text-align: center;
    width: 100%;
    box-sizing: border-box;

    &__item {
        width: @size*2.5;
        height: @size*2;
        display: inline-block;
        text-align: center;
        line-height: @size*3;
        cursor: pointer;
        /*background: var(--gray);*/

        &:before {
            content: '';
            border-radius: 50%;
            vertical-align: text-top;
            width: @size;
            height: @size;
            background: var(--secondary);

            transition: background .2s ease;
            display: inline-block;
        }


        &:not(&_active) {
            &:hover {
                &:before {
                    background: var(--secondary-light);
                }
            }
        }

        &_active {
            &:before {
                background: var(--primary);
            }
        }
    }

    &_left {
        text-align: left;
    }

    &_right {
        text-align: right;
    }

    &_static {
        position: relative;
        bottom: auto;
    }

    &_flat {
        @size: 26px;

        .points {
            &__item {
                width: @size*1.5;

                &:before {
                    width: @size;
                    height: 6px;
                    border-radius: 3px;
                    background: var(--white);
                }

                &:not(&_active) {
                    &:hover {
                        &:before {
                            background: var(--secondary);
                        }
                    }
                }

                &_active {
                    &:before {
                        background: var(--primary);
                    }
                }
            }
        }
    }
}
</style>
