import {createStore} from 'vuex'

import shelf from '@store/modules/core/shelf'
// import catalog from '@store/modules/catalog/catalog'

export const store = createStore({
    state: () => ({
        API_URL: '',
        messages: [],
        errors: []
    }),
    getters: {
        getMessages: (state) => state.messages,
    },
    mutations: {
        SET_MESSAGE(state, payload) {
            state.messages.push(payload);
        },
    },
    actions: {
        message({commit}, payload) {
            commit('SET_MESSAGE', payload);
        },
    },
    modules: {
        shelf,
        // catalog,
    }
})
