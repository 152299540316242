import useNavMain from "@use/data/core/nav_main";
import UseFindActivePath from "@use/findActivePath";


const shelf = {
    namespaced: true,
    state: () => ({
        show: false,
        social: [],
        contacts: [],
        items: null,
        loaded: true
    }),
    getters: {
        isShow: (state) => state.show,
        getItems: (state) => state.items
    },
    mutations: {
        SHOW(state, payload) {
            state.show = payload
        },
        SET_ITEMS(state, payload) {
            if (!state.items) {
                UseFindActivePath(payload)
                state.items = payload;
            }
        }
    },
    actions: {
        show({commit}, payload) {
            commit('SHOW', payload);
        },
        async getItem({commit}) {
            const {fetchData} = useNavMain();
            let data = await fetchData();

            if (data) {
                commit('SET_ITEMS', data);
            }
        }
    },
    modules: {}
}


export default shelf


