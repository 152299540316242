import {reactive, ref} from 'vue'

export default function useDrag(obj, emit, dist = 100, leave = true, outside = false) {
    const drag = ref(false);
    const target = ref(obj);

    const start_x = ref(0);
    const start_y = ref(0);

    const mouse_offset_x = ref(0);
    const mouse_offset_y = ref(0);

    const distance = dist;
    const touch = 'ontouchstart' in window;

    const target_move = ref(outside ? document: target.value);

    const callbacks = reactive({
        onSwipeLeft: function () {},
        onSwipeRight: function () {},
        onSwipeUp: function () {},
        onSwipeDown: function () {},

        onMouseDown: function () {},
        onMouseMove: function () {},
        onMouseUp: function () {},
        onMouseUpOutside: function () {},
    })

    function dragInit() {
        if (target.value) {
            if (touch) {
                target.value.addEventListener('touchstart', onMouseDown);
            } else {
                target.value.addEventListener('mousedown', onMouseDown);
            }
        }
    }

    function eventCheck(e) {
        if (e.touches && e.touches.length) {
            e = e.touches[0];
        } else if (e.changedTouches && e.changedTouches.length) {
            e = e.changedTouches[0];
        }

        return e;
    }

    function onMouseDown(e) {
        e = eventCheck(e)

        drag.value = true;
        start_x.value = e.clientX - target.value.offsetLeft;
        start_y.value = e.clientY - target.value.offsetTop;

        if (touch) {
            target_move.value.addEventListener('touchmove', onMouseMove);
            target.value.addEventListener('touchend', onMouseUp);
        } else {
            target_move.value.addEventListener('mousemove', onMouseMove);
            target.value.addEventListener('mouseup', onMouseUp);

            if (leave) target.value.addEventListener('mouseleave', onMouseUp);
        }

        callbacks.onMouseDown();
        emit('onMouseDown');

        if (outside) {
            document.body.addEventListener('mouseup', onMouseUpOutside, {once: true});
        }
    }

    function onMouseUpOutside() {
        // mouse_offset_x.value = 0;
        // mouse_offset_y.value = 0;

        if (touch) {
            target_move.value.removeEventListener('touchmove', onMouseMove);
            target.value.removeEventListener('touchend', onMouseUp);
        } else {
            target_move.value.removeEventListener('mousemove', onMouseMove);
            target.value?.removeEventListener('mouseup', onMouseUp);
            if (leave) target.value.removeEventListener('mouseleave', onMouseUp);
        }

        emit('onUpOutside')
        callbacks.onMouseUpOutside();
    }

    function onMouseMove(e) {
        e = eventCheck(e)

        let offset_position_x = e.clientX - target.value.offsetLeft;
        let offset_position_y = e.clientY - target.value.offsetTop;

        if (drag.value) {
            mouse_offset_x.value = offset_position_x - start_x.value;
            mouse_offset_y.value = offset_position_y - start_y.value;

            callbacks.onMouseMove()
            emit('onMouseMove')
        }
    }

    function onMouseUp() {
        drag.value = false;
        if (!drag.value) {
            if (mouse_offset_x.value > distance) {
                callbacks.onSwipeRight()
                emit('onSwipeRight')
            } else if (mouse_offset_x.value < -(distance)) {
                callbacks.onSwipeLeft()
                emit('onSwipeLeft')
            }

            if (mouse_offset_y.value > distance) {
                callbacks.onSwipeDown()
                emit('onSwipeUp')
            } else if (mouse_offset_y.value < -(distance)) {
                callbacks.onSwipeUp()
                emit('onSwipeDown')
            }

            callbacks.onMouseUp();
            mouse_offset_x.value = 0;
            mouse_offset_y.value = 0;
        }

        if (outside) {
            document.body.removeEventListener('mouseup', onMouseUpOutside, {once: true});
        }

        if (touch) {
            target_move.value.removeEventListener('touchmove', onMouseMove);
            target.value.removeEventListener('touchend', onMouseUp);
        } else {
            target_move.value.removeEventListener('mousemove', onMouseMove);
            target.value.removeEventListener('mouseup', onMouseUp);
            if (leave) target.value.removeEventListener('mouseleave', onMouseUp);
        }
    }

    return {
        target,

        drag,
        callbacks,

        start_x,
        start_y,

        mouse_offset_x,
        mouse_offset_y,

        dragInit,
    }
}
