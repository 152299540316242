import useForm from "@use/form";
// import baseField from "@use/form/fields/baseField";

import phoneField from "@use/form/fields/phone";
import nameField from "@use/form/fields/name";

export default function useFeedbackForm(compact = false) {
    return useForm({
        captcha: true,
        description: null,
        compact,
        field: {
            name: nameField,
            phone: phoneField,
        },
    })
}
