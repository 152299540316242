<template>
    <div class="price">{{value}} <span class="price__unit">{{unit}}</span></div>
</template>

<script>
    import {computed} from "vue";
    import {priceFormat} from "@utils/string";

    export default {
        name: "Price",
        props: {
            price: {
                type: Number,
                default: null,
            },
            unit: {
                type: String,
                default: '₽'
            }
        },
        setup(props) {
            let value = computed(() => priceFormat(props.price))
            return {
                value
            }
        }
    }
</script>

<style></style>
