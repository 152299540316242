<template>
    <div v-if="images" class="offer-images">
        <v-points :points="images?.length"
                  :index="position"
                  class="offer-images__points points_flat"
                  @onChange="switchPointHandler"
        ></v-points>

        <div v-if="images?.length" class="offer-images__switcher">
            <div v-for="(item, index) in images"
                 :key="item"
                 class="offer-images__switcher-item"
                 @mouseenter="switchHandler(item, index)"
            >
            </div>
        </div>

        <transition name="opacity" mode="in-out">
            <v-image :src="image"
                     class="image_full offer-images__image"
                     :exist="image ? true : false"
                     :key="image"
                     :alt="name"></v-image>
        </transition>
    </div>
</template>

<script>
import {onMounted, ref} from "vue";

import Image from "@components/ui/Image";
import Points from "@components/ui/Points";

export default {
    name: "ObjectImages",
    props: {
        images: {
            type: Array,
            default: null
        },
        thumbnail: {
            type: String,
            default: null
        },
        name: {
            type: String,
            default: null
        }
    },
    components: {
        'v-image': Image,
        'v-points': Points,
    },
    setup(props) {
        let image = ref(null);
        let position = ref(null);
        let show = ref(false);

        onMounted(() => {
            if (props?.images?.length) {
                image.value = props?.images[0]?.url;
                position.value = 0;
            }
        })

        function switchPointHandler(value) {
            position.value = value;
            image.value = props?.images[value]?.url;
        }

        function switchHandler(value, index) {
            image.value = value.url;
            position.value = index;
        }

        return {
            show,
            image,
            position,
            switchHandler,
            switchPointHandler
        }
    }
}
</script>

<style lang="less">
.offer-images {
    overflow: hidden;
    width: 100%;
    height: 100%;

    &__container {
        height: 100%;
    }

    &__switcher {
        width: 100%;
        height: 100%;
        display: flex;
        position: absolute;
        inset: 0;
        z-index: 1;

        &-item {
            height: 100%;
            width: 100%;
            opacity: 0;
            background: var(--white);
            transition: opacity .2s ease-in;

            &:hover{
                opacity: .2;
            }
        }
    }

    &__image {
        width: 100%;
        height: 180px;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        position: absolute;
        top: 0;
        left: 0;

        img {
            height: 100%;
            object-fit: cover;
        }
    }
}
</style>
