export default function UseFindActivePath(nodes) {
    let path = window.location.pathname,
        pathList = path.split('/'),
        rootLevel = `/${pathList[1]}`

    let list = [];

    function findItem(node) {
        if (node.children) {
            for (const item of node.children) {
                if (item.children) {
                    if (item.url === path) {
                        return [item];
                    }

                    let result = findItem(item);
                    if (result) {
                        return [node, ...result];
                    }
                } else {
                    if (item.url === path) {
                        return [node, item];
                    }
                }
            }
        } else {
            if (node.url === path) {
                return [node];
            }
        }
    }

    for (const item of nodes) {
        let result = findItem(item);
        if (result) {
            list = [...result];
            if (item.url === rootLevel) {
                break;
            }
        }
    }

    for (const item of list) {
        item.active = true;
    }
}
