import useFetch from "@use/fetch";
import config from "@config/config";

export default function useFeedback(data) {
    return {
        ...useFetch(`/api/v1/feedback/`, {
            method: 'POST',
            headers: config.headers,
            body: JSON.stringify(data)
        })
    }
}
