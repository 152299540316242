<template>
    <div class="offer-item">
        <div class="offer-item__gallery" ondragstart="return false">
            <span class="offer-item__label"
                  :class="{'offer-item__label_sale': type}"
            >{{ type ? 'Продажа' : 'Аренда' }}</span>

            <v-offer-images
                :thumbnail="thumbnail"
                :images="images"
            ></v-offer-images>
        </div>

        <div class="offer-item__info">
            <div class="offer-item__area-floor">
                <div class="offer-item__area"><strong>{{ area ? area : '-' }}</strong> м²</div>
                <div class="offer-item__floor">Этаж <strong>{{ floor }}</strong> из {{ floors }}</div>
            </div>

            <a :href="link" class="offer-item__name">
                <span class="offer-item__rooms">{{ name }}</span>
                <div class="offer-item__address">{{ location }}</div>
            </a>

            <div class="offer-item__price">
                <v-price :price="price" :unit="get_period"></v-price>
                <v-price v-if="price_per_meter"
                         :price="price_per_meter"
                         class="price_sm offer-item__price-per-meter"
                         unit="руб. за м²"></v-price>
            </div>

            <div v-if="additionally"
                 class="offer-item__additionally"
                 :class="`offer-item__additionally_${additionally}`">{{ additionally }}
            </div>
        </div>
    </div>
</template>

<script>
import Price from "@components/catalog/Price";
import ObjectImages from "@components/catalog/offers/OfferImages";
import {computed} from "vue";

export default {
    name: "Offer",
    components: {
        'v-price': Price,
        'v-offer-images': ObjectImages,
    },
    props: {
        id: {
            type: Number,
            default: null,
        },
        name: {
            type: String,
            default: null,
        },
        unit: {
            type: String,
            default: 'руб.',
        },
        location: {
            type: String,
            default: null,
        },
        rooms: {
            type: Number,
            default: null,
        },
        area: {
            type: Number,
            default: null,
        },
        floor: {
            type: Number,
            default: null,
        },
        floors: {
            type: Number,
            default: null,
        },
        link: {
            type: String,
            default: null,
        },
        additionally: {
            type: String,
            default: null,
        },
        price: {
            type: Number,
            default: null,
        },
        period: {
            type: Number,
            default: null,
        },
        price_per_meter: {
            type: Number,
            default: null,
        },
        thumbnail: {
            type: String,
            default: null,
        },
        images: {
            type: Array,
            default: null,
        },
        type: {
            type: Number,
            default: null,
        },
        compact: {
            type: Boolean,
            default: false,
        },
    },
    setup(props) {
        let get_period = computed(() => {
            return `руб.${props.period ? props.period === 1 ? '/мес.' : '/день' : ''}`
        })

        return {get_period}
    }
}
</script>

<style lang="less"></style>
